import axios from 'axios';
import { useEffect, useState } from 'react';
import { LoginModel } from '../components/forms/LoginModel';
import { getQueryVariable } from '../components/utils';

interface AuthenticationResponse {
  isOk: boolean;
  is2Fa: boolean;
  obfuscatedPhoneNumber: string;
  redirectUrl: string;
}

const useAuthentication = (loginModel: LoginModel) => {
  const [results, setResults] = useState<AuthenticationResponse>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  useEffect(() => {
    async function fetchData(values: LoginModel) {
      try {
        const returnUrl = getQueryVariable('ReturnUrl');
        setLoading(true);
        setError(false);

        const url = 'api/authenticate';

        const body = JSON.stringify({
          username: values.EmailAddress,
          password: values.Password,
          passcode: values.Passcode,
          dateofbirth: values.DateOfBirth,
          returnUrl,
        });

        const response = await axios.post(url, body, {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status == 200) {
          setResults(response.data);
          setLoading(false);
        } else {
          setResults(response.data);
          setLoading(false);
          setError(true);
        }

        return response;
      } catch (error) {
        if (error.message != 'Operation canceled by the user.') {
          setLoading(false);
        }
        setError(true);
      }
    }

    if (loginModel.EmailAddress !== '' && loginModel.Password !== '') {
      fetchData(loginModel);
    }

    return function cleanup() {
      source.cancel('Operation canceled by the user.');
      setLoading(false);
    };
  }, [loginModel]);

  return { results, loading, error };
};

export default useAuthentication;
