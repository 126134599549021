import { Box, CircularProgress, Grid } from '@material-ui/core';
import { Field, Form } from 'formik';
import React from 'react';
import useDisableContinue from '../../hooks/useDisableContinue';
import MaterialTextField from '../MaterialTextField';
import StyledButton from '../StyledButton';

interface Props {
    isLoading: boolean;
    onReturnToLoginClick: () => void;
}

const ForgotPasswordForm = ({
    isLoading,
    onReturnToLoginClick: handleReturnToLoginClick,
}: Props) => {
    const { isDisabled } = useDisableContinue();

    return (
        <Form noValidate>
            <Field
                name="ForgotPasswordEmailAddress"
                id="forgot-password-email-address"
                label="Email address"
                component={MaterialTextField}
            />
            <Grid container justify="center" alignItems="center">
                <Grid item xs={6}>
                    <Box mr={2}>
                        <StyledButton
                            data-testid="send-verification-email-button"
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={isDisabled || isLoading}
                        >
                            <>
                                Send verification email&nbsp;&nbsp;
                                {isLoading && <CircularProgress size={24} />}
                            </>
                        </StyledButton>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <StyledButton
                        data-testid="return-to-log-in-page-button"
                        type="button"
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={handleReturnToLoginClick}
                    >
                        Return to login page
                    </StyledButton>
                </Grid>
            </Grid>
        </Form>
    );
};

export default ForgotPasswordForm;
