import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

interface Props {
    showClose?: boolean;
    children: JSX.Element;
    onClick?: (_: React.MouseEvent<HTMLElement>) => void;
    centerTitle?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalHeader: {
            borderBottom: '1px solid #d3d3d3',
            padding: theme.spacing(2, 4),
        },
        closeButtonContainer: {
            cursor: 'pointer',
            color: '#e1e1e1',
            paddingLeft: theme.spacing(1),
        },
        content: (styleProps: any) => ({
            textAlign: styleProps.centerTitle ? 'center' : 'left',
            color: theme.palette.text.primary,
        }),
    })
);

const ModalHeader = ({ showClose, onClick, children, centerTitle = false }: Props) => {
    const classes = useStyles({ centerTitle });
    return (
        <div className={classes.modalHeader}>
            <Grid container>
                <Grid item xs>
                    <Box className={classes.content}>{children}</Box>
                </Grid>
                {showClose && (
                    <Grid item>
                        <div className={classes.closeButtonContainer}>
                            <a onClick={onClick} data-testid='modal-close'>
                                <CloseIcon />
                            </a>
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default ModalHeader;
