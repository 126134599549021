import axios from 'axios';
import { useEffect, useState } from 'react';
import { LoginModel } from '../components/forms/LoginModel';
import { TwoFactorAuthResultEnum } from '../components/enums';
import { getQueryVariable } from '../components/utils'

interface RegenPasscodeResponse {
    regenResult: TwoFactorAuthResultEnum;
}

const useResendPasscode = (loginModel: LoginModel, regenPasscodeRequest: Boolean) => {
    const [regenLoading, setRegenLoading] = useState(false);
    const [regenError, setRegenError] = useState(false);
    const [regenResults, setRegenResults] = useState<RegenPasscodeResponse>();
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        async function sendRegenPasscodeRequest(values: LoginModel) {
            try {
                setRegenLoading(true);

                const returnUrl = getQueryVariable('ReturnUrl');

                const url = 'api/authenticate/RegenPasscode';

                const body = JSON.stringify({
                    username: values.EmailAddress,
                    password: values.Password,
                    returnUrl,
                });

                const response = await axios.post(url, body, {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status == 200) {
                    setRegenResults(response.data);
                    setRegenLoading(false);
                } else {
                    setRegenResults(response.data);
                    setRegenLoading(false);
                    setRegenError(true);
                }

                return response;

            } catch (error) {
                if (error.message != 'Regen Passcode operation canceled by the user.') {
                    setRegenLoading(false);
                }
                setRegenError(true);
            }
        }

        if (loginModel.EmailAddress !== '' && loginModel.Password !== '') {
            sendRegenPasscodeRequest(loginModel);
        }

        return function cleanup() {
            source.cancel('Operation canceled by the user.');
            setRegenLoading(false);
        };
    }, [regenPasscodeRequest]);

    return { regenResults, regenLoading, regenError };
};

export default useResendPasscode;
