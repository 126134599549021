import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { makeStyles, Container } from '@material-ui/core';

const useStyles = makeStyles(_ => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
}));

interface Props {
    children: JSX.Element | JSX.Element[];
}

export const Layout = ({ children }: Props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Header />
            <main>
                <Container>{children}</Container>
            </main>
            <Footer />
        </div>
    );
};
