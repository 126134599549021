import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import { FieldProps } from 'formik';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: (styleProps: any) => ({
            margin: styleProps.showLabel ? theme.spacing(2.5, 0) : theme.spacing(0, 0, 2.5),
            '& label.MuiFormLabel-root': {
                color: theme.palette.text.primary,
                fontFamily: 'Spartan MB SemiBold',
                fontSize: '1rem',
                transform: 'scale(1)',
                lineHeight: '1.25rem',
                marginTop: '0rem',
                marginRight: '48px',
                position: 'relative',
            },
            '& .MuiInput-formControl': {
                marginTop: '0px',
            },
            '& .MuiInput-input': {
                fontFamily: 'Spartan MB ',
                fontSize: '0.875rem',
                paddingBottom: 10,
                paddingTop: 12,
                textTransform: styleProps.textTransform || 'none',
            },
            '& .MuiInput-underline:before': {
                borderBottom: '2px solid #e5e5e5',
            },
            '& .MuiInput-underline': {
                '&:after, &:hover:before': {
                    borderBottomColor: theme.palette.secondary.main,
                },
            },
            '&::placeholder': {
                fontSize: '0.875rem',
            },
        }),
        labelRoot: {
            color: theme.palette.text.primary,
        },
    }),
);

interface InputProps {
    textTransform: 'none' | 'uppercase' | 'capitalize';
}

const MaterialTextField = <P extends object>({
    textTransform = 'none',
    field,
    form: { touched, errors },
    ...props
}: FieldProps<P> & InputProps) => {
    const get = (o: any, path: string) =>
        path.split('.').reduce((o: any = {}, key: string) => o[key], o);

    const touchedField = get(touched, field.name);
    const errorsField = get(errors, field.name);
    const showLabel = !!({ ...props } as any).label;

    const classes = useStyles({ showLabel, textTransform });

    return (
        <TextField
            className={classes.textField}
            fullWidth
            margin="normal"
            helperText={touched && touchedField ? errors && errorsField : ''}
            error={touched && touchedField && errors && Boolean(errorsField)}
            inputProps={{
                maxLength: 100,
            }}
            InputLabelProps={{
                shrink: true,
                classes: {
                    root: classes.labelRoot,
                },
            }}
            {...props}
            {...field}
        />
    );
};

export default MaterialTextField;
