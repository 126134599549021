import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { Field, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import useDisableContinue from '../../hooks/useDisableContinue';
import MaterialTextField from '../MaterialTextField';
import { LinkButton } from '../StyledButton';

interface Props {
    isLoading: boolean;
    isWaiting2fa: boolean;
    timer: number;
    resendClicked: () => void;
}

const TwoFactorAuthForm = ({ isLoading, isWaiting2fa, resendClicked, timer }: Props) => {
    const { isDisabled } = useDisableContinue();

    return (
        <Form noValidate>
            <Field
                name="PhoneNumber"
                id="login-email-address"
                label="Mobile Phone Number"
                component={MaterialTextField}
                disabled
            />
            <Field
                name="Passcode"
                id="login-passcode"
                label="Passcode"
                component={MaterialTextField}
                type="password"
            />
            <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                <Grid item xs={12}>
                    <Box px={2}>
                        <Button
                            data-testid="login-sign-in"
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={isDisabled || isLoading}
                        >
                            <>Submit&nbsp;&nbsp;{isLoading && <CircularProgress size={24} />}</>
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <LinkButton
                        data-testid="login-resend"
                        disabled={isLoading || isWaiting2fa}
                        fullWidth
                        onClick={resendClicked}
                    >
                        {`Not received a code? Click to resend ${timer > 0 ? `(${timer})` : ''}`}
                    </LinkButton>
                </Grid>
            </Grid>
        </Form>
    );
};

export default TwoFactorAuthForm;
