import { withStyles, Button, Theme } from '@material-ui/core';

const StyledButton = withStyles({
    root: {
        fontFamily: 'Spartan MB Semibold',
        fontSize: '0.8125rem',
    },
})(Button);

export const LinkButton = withStyles((theme: Theme) => ({
    root: {
        color: theme.palette.primary.main,
        fontFamily: 'Spartan MB Semibold',
        fontSize: '0.8125rem',
        padding: 0,
        '&:focus, &:hover': {
            textDecoration: 'underline',
            backgroundColor: '#ffffff00',
        },
    },
}))(Button);

export default StyledButton;
