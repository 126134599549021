import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        footer: {
            backgroundColor: string;
        };
    }
    interface ThemeOptions {
        footer?: {
            backgroundColor?: string;
        };
    }
}

// A custom theme for this app
const theme = createMuiTheme({
    props: {
        MuiTextField: {
            margin: 'normal',
            InputLabelProps: {
                shrink: true,
            },
        },
    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#291b44',
            },
        },
        MuiPaper: {
            root: {},
            rounded: {
                borderRadius: 5,
            },
            elevation4: {
                filter: 'drop-shadow(0px 9px 8px rgba(152,184,190,0.31))',
                boxShadow: 'none',
            },
        },
        MuiButton: {
            root: {
                color: '#9d9d9d',
                fontFamily: 'Spartan MB Bold',
                fontSize: '1rem',
                textTransform: 'none',
            },
            outlined: {
                borderColor: '#e5e5e5',
                border: 'solid',
                borderWidth: 2,
                padding: '9px 15px',
            },
            outlinedPrimary: {
                color: '#00c9af',
            },
            contained: {
                padding: 11,
                boxShadow: '0px 9px 8px rgba(0,201,175,0.22)',
                '&:focus, &:hover': {
                    boxShadow: '0px 9px 8px rgba(0,201,175,0.22)',
                },
            },
            containedPrimary: {
                backgroundColor: '#00c9af',
                '&:focus, &:hover': {
                    backgroundColor: '#00c9af',
                },
            },
            containedSecondary: {
                backgroundColor: '#291b44',
                '&:focus, &:hover': {
                    backgroundColor: '#291b44',
                },
            },
            text: {
                //padding: "6px 50px",
            },
        },
        MuiInputBase: {
            root: {
                fontSize: '1rem',
                lineHeight: '1.875rem',
            },
            input: {
                padding: '12px 0 10px',
            },
        },
        MuiInputLabel: {
            shrink: {
                transform: 'scale(1)',
            },
        },
        MuiInput: {
            formControl: {
                marginTop: 32, //theme.spacing(2)
                fontSize: '0.875rem',
            },
            underline: {
                '&:before': {
                    borderBottomWidth: 2,
                    borderBottomColor: '#e5e5e5',
                },
                //focused
                // '&:after': {
                //     borderBottomWidth: 2,
                //     borderBottomColor: 'black',
                // }
            },
        },
        MuiFormHelperText: {
            root: {
                error: {
                    fontSize: '1rem',
                    color: '#f30a2f',
                },
            },
        },
        MuiFormLabel: {
            root: {
                fontFamily: 'Spartan MB SemiBold',
                fontSize: '1rem',
                lineHeight: '1.25rem',
                color: '#000000',
                // '&.Mui-focused': {
                //     color: 'black'
                // }
            },
        },
        MuiTypography: {
            h1: {
                margin: '40px 0 18px',
                fontFamily: 'Spartan MB ExtraBold',
                fontSize: '1.875rem',
                lineHeight: '40px',
                textAlign: 'center',
                color: '#291b44',
            },
            h2: {
                margin: '8px',
                fontFamily: 'Spartan MB SemiBold',
                fontSize: '1rem',
                lineHeight: '20px',
                textAlign: 'center',
            },
            h3: {
                fontSize: '1.25rem',
                fontFamily: 'Spartan MB Bold',
                margin: '8px 0',
            },
            body1: {
                fontSize: '0.9375rem',
            },
        },
    },
    typography: {
        fontSize: 16,
        fontFamily: ['Spartan MB', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    palette: {
        primary: {
            main: '#291b44',
        },
        secondary: {
            light: '#00c9af',
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#f8f8f8',
        },
    },
    shape: {
        borderRadius: 25,
    },
    footer: {
        backgroundColor: 'transparent',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1108, // originally was 1280
            xl: 1920,
        },
    },
});

export default theme;
