import * as Yup from 'yup';

export interface SetPasswordModel {
    Password: string;
    RetypedPassword: string;
    Code: string;
}

export const SetPasswordModelInitial: SetPasswordModel = {
    Password: '',
    RetypedPassword: '',
    Code: '',
};

export interface ErrorResponse {
    errorCode: SetPasswordErrorCode;
    message: string;
}

export interface TimeoutCheckResponse {
    hasTimedOut: boolean;
    message: string;
}

export enum SetPasswordErrorCode {
    None = 0,
    TimedOut = 10,
    AlreadyUsed = 20,
    InputSquaredError = 30,
    Exception = 40,
}

export const NewPasswordModelSchema = Yup.object({
    Password: Yup.string()
        .required('Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/([A-Z])+/g, 'Must contain at least 1 capital')
        .matches(/([a-zA-Z])+/g, 'Must have a mixture of letters and numbers')
        .matches(/([0-9])+/g, 'Must have a mixture of letters and numbers'),
    RetypedPassword: Yup.string()
        .required('Please confirm your password')
        .oneOf([Yup.ref('Password'), null], 'Passwords must match'),
});
