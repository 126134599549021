import * as Yup from 'yup';
const emailRegex = new RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]+)$/, 'i');
const safeAndSecureIdRegex = new RegExp(/^\d+$/);

export interface LoginModel {
  EmailAddress: string;
  Password: string;
  Passcode?: string;
  DateOfBirth?: string;
}

export const LoginInitial: LoginModel = {
  EmailAddress: '',
  Password: '',
  Passcode: '',
  DateOfBirth: '',
};

const emailAddress = Yup.lazy((value: string) => {
  if (safeAndSecureIdRegex.test(value)) {
    return Yup.string().required().length(6, 'Please enter a valid email address');
  } else {
    return Yup.string().matches(emailRegex, 'Please enter a valid email address').required('Email address is required');
  }
});

const passcode = Yup.lazy(() => {
  return Yup.string().required().length(6, 'Passcode must be 6 digits');
});

const dob = Yup.lazy(() => {
  return Yup.string()
    .required('Date of birth is required')
    .test('test-minimum-age', 'Must be a valid date', (value) => {
      const dob = Date.parse(value);
      return dob !== NaN;
    });
});

export const LoginValidationSchema = Yup.object({
  EmailAddress: emailAddress,
  Password: Yup.string().required('Password is required'),
});

export const TwoFactorAuthValidationSchema = Yup.object({
  Passcode: passcode,
});

export const DobAuthSchema = Yup.object({
  DateOfBirth: dob,
});

export const ForgotPasswordValidationSchema = Yup.object({
  ForgotPasswordEmailAddress: Yup.string()
    .email('Please enter a valid email address')
    .required('Email address is required'),
});
