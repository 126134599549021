import { useState, useEffect } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

const useAxios = (url: string, config: AxiosRequestConfig | undefined) => {
    const [data, updateData] = useState(undefined);

    useEffect(() => {
        async function fetchData() {
            const response = await axios.get(url, config);
            const json = await response.data;
            updateData(json);
        }
        fetchData();
    }, [url]);

    return data;
};

export default useAxios;
