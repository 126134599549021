import { Avatar } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MuiLink from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import LoginForm from './forms/LoginForm';
import TwoFactorAuthForm from './forms/TwoFactorAuthForm';
import ForgotPasswordForm from './forms/ForgotPasswordForm';
import {
  LoginModel,
  LoginValidationSchema,
  LoginInitial,
  TwoFactorAuthValidationSchema,
  ForgotPasswordValidationSchema,
  DobAuthSchema,
} from './forms/LoginModel';
import useAuthentication from '../hooks/useAuthentication';
import useResendPasscode from '../hooks/useResendPasscode';
import { Box } from '@material-ui/core';
import Modal, { ModalWidth } from './modal/Modal';
import useForgotPassword from '../hooks/useForgotPassword';
import StyledButton, { LinkButton } from './StyledButton';
import CustomerPortalLoginForm from './forms/CustomerPortalLoginForm';
import useCustomerPortal from '../hooks/useCustomerPortal';

const brokerAppUrl = process.env.REACT_APP_BROKER_APP_URL || 'http://localhost:8000';
const brokerAppStagingUrl = process.env.REACT_APP_BROKER_APP_STAGING_URL || 'https://quotes.test.uinsure.co.uk';

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
  },
  header: {
    margin: 0,
  },
  twoFaCopy: {
    margin: theme.spacing(1, 0),
  },
  loginForm: {
    paddingBottom: theme.spacing(2),
  },
  invalidLogin: {
    paddingBottom: theme.spacing(2),
  },
  link: {
    margin: theme.spacing(1, 2),
    fontSize: '0.8125rem',
    fontFamily: 'Spartan MB SemiBold',
  },
}));

enum DirectLoginType {
  None,
  Standard,
  Portal,
}

const Login = () => {
  const classes = useStyles();
  const [displayError, setDisplayError] = useState(false);
  const [directLogin, setDirectLogin] = useState(DirectLoginType.None);
  const [submitLoginModel, setSubmitLoginModel] = useState<LoginModel>(LoginInitial);
  const [regenPasscode, setRegenPasscode] = useState(false);
  const [is2FA, setIS2FA] = useState(false);
  const [obfuscatedPhone, setObfuscatedPhone] = useState('');
  const [isForgotPasswordOpen, setForgotPasswordOpen] = useState<boolean>(false);
  const [isForgotPasswordRequest, setForgotPasswordRequest] = useState<boolean>(false);
  const [forgotPasswordEmailAddress, setForgotPasswordEmailAddress] = useState<string>('');
  const [isForgotPasswordRequestFinished, setForgotPasswordRequestFinished] = useState<boolean>(false);

  const query = window.location.search;
  const queryParams = new URLSearchParams(unescape(window.location.search));
  const hasReturnUrl = query && query.toLowerCase().indexOf('?returnurl=') == 0;
  const { results, loading, error } = useAuthentication(submitLoginModel);
  const {
    result: postcodeResult,
    error: errorPostcode,
  } = useCustomerPortal(queryParams.get('portalcode'), submitLoginModel);
  const { regenResults, regenLoading, regenError } = useResendPasscode(submitLoginModel, regenPasscode);
  const {
    result: forgotPasswordResult,
    isLoading: isForgotPasswordLoading,
    error: forgotPasswordError,
  } = useForgotPassword(forgotPasswordEmailAddress, isForgotPasswordRequest, () => {
    setForgotPasswordRequestFinished(true);
  });

  if (query.indexOf('payload') > 0) {
    if (directLogin === DirectLoginType.None) {
      setDirectLogin(DirectLoginType.Standard);
      setSubmitLoginModel({ EmailAddress: 'directlogin', Password: 'directlogin' });
    }
  }

  if (query.indexOf('portalcode') > 0) {
    if (directLogin === DirectLoginType.None) {
      setDirectLogin(DirectLoginType.Portal);
    }
  }

  useEffect(() => {
    if (results) {
      if (results.isOk && results.redirectUrl) window.location.href = results.redirectUrl;
      else if (results.is2Fa) {
        setIS2FA(true);
        start2faTimer();
        setObfuscatedPhone(results.obfuscatedPhoneNumber);
      }
    }
  }, [results]);

  useEffect(() => {
    if (!hasReturnUrl) {
      if (window.location.href.indexOf('idp.test') > 0) {
        window.location.href = brokerAppStagingUrl;
      } else {
        window.location.href = brokerAppUrl;
      }
    }
  }, []);

  const handleSubmit = (values: LoginModel) => {
    setSubmitLoginModel(values);
  };

  const handleForgotPasswordSubmit = (emailAddress: string) => {
    setForgotPasswordEmailAddress(emailAddress);
    setForgotPasswordRequest(true);
  };

  const handleResend = () => {
    setRegenPasscode(!regenPasscode);
    start2faTimer();
  };

  const handleForgotPasswordClose = () => {
    if (!isForgotPasswordLoading) {
      setForgotPasswordOpen(false);
      setTimeout(() => {
        setForgotPasswordRequest(false);
        setForgotPasswordRequestFinished(false);
      }, 100);
    }
  };

  const handleForgotPasswordClick = () => {
    setForgotPasswordEmailAddress('');
    setForgotPasswordOpen(true);
  };

  const hasChangedValues = (values: LoginModel) => {
    if (submitLoginModel.EmailAddress !== values.EmailAddress ||
        submitLoginModel.Password !== values.Password) {
      setDisplayError(false);
      return false;
    }
    return false;
  };

  const dateOfBirthHasChanged = (values: LoginModel) => {
    const changed = values.DateOfBirth !== submitLoginModel.DateOfBirth;
    if (changed) {
      setDisplayError(false)
    }
    return changed;
  }

  useEffect(() => {
    if (error || errorPostcode) {
      setDisplayError(true);
    }
  }, [error, errorPostcode]);

  const [isWaiting2fa, setWaiting2fa] = useState<boolean>(false);
  const [timer2fa, setTimer2fa] = useState<number>(0);

  useEffect(() => {
    let interval: any = null;
    if (isWaiting2fa) {
      interval = setInterval(() => {
        setTimer2fa((timer2fa) => timer2fa - 1);
      }, 1000);
    } else {
      if (interval != null) {
        clearInterval(interval);
      }
    }

    if (timer2fa <= 0) {
      setWaiting2fa(false);
      setTimer2fa(0);
    }

    return () => clearInterval(interval);
  }, [isWaiting2fa, timer2fa]);

  const start2faTimer = () => {
    setTimer2fa(10);
    setWaiting2fa(true);
  };

  return (
    <>
      <Container maxWidth={'xs'}>
        {hasReturnUrl && (
          <Box mb={4}>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography variant='h1' className={classes.header}>
                Login
              </Typography>
              {directLogin === DirectLoginType.None && !is2FA && (
                <>
                  <Formik
                    initialValues={{
                      EmailAddress: submitLoginModel.EmailAddress,
                      Password: submitLoginModel.Password,
                    }}
                    validationSchema={LoginValidationSchema}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {({ values }: FormikProps<LoginModel>) => (
                      <>
                        <Box pb={2}>
                          <LoginForm isLoading={loading} />
                        </Box>
                        {displayError && !hasChangedValues(values) && (
                          <Grid
                            container
                            direction='column'
                            justify='center'
                            alignItems='center'
                            className={classes.invalidLogin}
                          >
                            <Grid item>
                              <Typography data-testid='invalid-login' color='error'>
                                Invalid login credentials
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                  </Formik>
                  <Grid container direction='column' justify='center' alignItems='center'>
                    <Grid item>
                      <LinkButton disableRipple onClick={handleForgotPasswordClick}>
                        Forgot password?
                      </LinkButton>
                    </Grid>
                    <Grid item>
                      <MuiLink
                        color='inherit'
                        href='https://www.uinsure.co.uk/register/'
                        target='_blank'
                        className={classes.link}
                      >
                        Don't have an account? Sign Up
                      </MuiLink>
                    </Grid>
                  </Grid>
                </>
              )}
              {directLogin === DirectLoginType.None && is2FA && (
                <>
                  <Typography variant='body1' className={classes.twoFaCopy}>
                    You will shortly receive a 6 digit passcode by SMS to the telephone number below.
                  </Typography>
                  <Typography variant='body1' className={classes.twoFaCopy}>
                    If you dont receive a code within 60 seconds request another code below. If you have any issues
                    please contact 0344 844 3844.
                  </Typography>
                  <Formik
                    initialValues={{
                      EmailAddress: submitLoginModel.EmailAddress,
                      Password: submitLoginModel.Password,
                      PhoneNumber: obfuscatedPhone,
                      Passcode: '',
                    }}
                    validationSchema={TwoFactorAuthValidationSchema}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {({ values }: FormikProps<any>) => (
                      <>
                        <Grid item className={classes.loginForm}>
                          <TwoFactorAuthForm
                            isLoading={loading}
                            isWaiting2fa={isWaiting2fa}
                            timer={timer2fa}
                            resendClicked={handleResend}
                          />
                        </Grid>
                        {displayError && !hasChangedValues(values) && (
                          <Grid
                            container
                            direction='column'
                            justify='center'
                            alignItems='center'
                            className={classes.invalidLogin}
                          >
                            <Grid item>
                              <Typography data-testid='invalid-login' color='error'>
                                Passcode incorrect
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                  </Formik>
                </>
              )}
              {directLogin === DirectLoginType.Portal && (
                <>
                  <Typography variant='body1' className={classes.twoFaCopy}>
                    Access your renewal documents online.
                  </Typography>
                  <Typography variant='body1' className={classes.twoFaCopy}>
                    Welcome back {postcodeResult?.firstName}, we just need a few details to retrieve your renewal for{' '}
                    <strong>{postcodeResult.postcode}</strong>
                  </Typography>
                  <Formik
                    initialValues={{
                      EmailAddress: submitLoginModel.EmailAddress,
                      Password: submitLoginModel.Password,
                      PhoneNumber: '',
                      Passcode: '',
                      DateOfBirth: '',
                    }}
                    validationSchema={DobAuthSchema}
                    onSubmit={(values) => {
                      handleSubmit({ ...values, EmailAddress: 'portallogin', Password: 'portallogin' });
                    }}
                  >
                    {({ values }: FormikProps<any>) => (
                      <>
                        <Grid item className={classes.loginForm}>
                          <CustomerPortalLoginForm isLoading={loading} />
                        </Grid>
                        {displayError && !dateOfBirthHasChanged(values) && (
                          <Grid
                            container
                            direction='column'
                            justify='center'
                            alignItems='center'
                            className={classes.invalidLogin}
                          >
                            <Grid item>
                              <Typography data-testid='invalid-login' color='error'>
                                Date of birth is incorrect
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                  </Formik>
                </>
              )}
            </div>
          </Box>
        )}
      </Container>
      <Modal
        data-testid='forgot-password-modal'
        title='Forgot password'
        onClose={handleForgotPasswordClose}
        open={isForgotPasswordOpen}
        width={ModalWidth._560}
      >
        {isForgotPasswordRequestFinished ? (
          forgotPasswordResult && !forgotPasswordError ? (
            <>
              <Box mt={2} mb={3}>
                <Typography variant='body1'>
                  If your email address is linked to an account you will shortly receive an email with a link to reset
                  your password.
                </Typography>
              </Box>
              <Box display='flex' justifyContent='center' mt={2} mb={3} mx={5}>
                <StyledButton
                  data-testid='return-to-log-in-page-button'
                  type='button'
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={handleForgotPasswordClose}
                >
                  Return to login page
                </StyledButton>
              </Box>
            </>
          ) : (
            <>
              <Box mt={2} mb={3}>
                <Typography variant='body1'>
                  Unable to submit request. Call us on 0344 844 3844 if you require further assistance.
                </Typography>
              </Box>
              <Box display='flex' justifyContent='center' mt={2} mb={3} mx={5}>
                <StyledButton
                  data-testid='return-to-log-in-page-button'
                  type='button'
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={handleForgotPasswordClose}
                >
                  Return to login page
                </StyledButton>
              </Box>
            </>
          )
        ) : (
          <>
            <Box px={3}>
              <Box mt={2} mb={3}>
                <Typography variant='body1'>To reset your password please enter your email address below.</Typography>
              </Box>
              <Box mt={2} mb={3}>
                <Typography variant='body1'>You should receive an email within the next 10 minutes.</Typography>
              </Box>
              <Formik
                initialValues={{
                  ForgotPasswordEmailAddress: forgotPasswordEmailAddress,
                }}
                validationSchema={ForgotPasswordValidationSchema}
                onSubmit={(values) => {
                  handleForgotPasswordSubmit(values.ForgotPasswordEmailAddress);
                }}
              >
                <Grid item className={classes.loginForm}>
                  <ForgotPasswordForm
                    isLoading={isForgotPasswordLoading}
                    onReturnToLoginClick={handleForgotPasswordClose}
                  />
                </Grid>
              </Formik>
            </Box>
          </>
        )}
      </Modal>
    </>
  );
};

export default Login;
