import { Container, Divider, Grid, makeStyles } from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';
import React, { useEffect, useState } from 'react';
import Copyright from './Copyright';

const brokerAppUrl = process.env.REACT_APP_BROKER_APP_URL || 'http://localhost:8000';
const brokerAppStagingUrl = process.env.REACT_APP_BROKER_APP_STAGING_URL || 'https://quotes.test.uinsure.co.uk';

const useStyles = makeStyles(theme => ({
    footer: {
        paddingTop: '2rem',
        paddingBottom: '4rem',
        marginTop: 'auto',
        backgroundColor: theme.footer.backgroundColor,
        textAlign: 'center',
        '& nav a': {
            margin: theme.spacing(1.5, 2.25),
            display: 'inline-block',
        },
    },
    footerLinksContainer: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            marginBottom: '35px',
            marginTop: '40px',
        },
    },
    footerLink: {
        fontFamily: 'Spartan MB SemiBold',
        fontSize: '0.75rem',
        textAlign: 'center',
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            margin: '7px 18px',
        },
    },
}));

const Footer = () => {
    const classes = useStyles();
    const [uinsureUrl, setUinsureUrl] = useState('');


    useEffect(() => {
        if (window.location.href.indexOf("idp.test") > 0) {
            setUinsureUrl(brokerAppStagingUrl);
        } else {
            setUinsureUrl(brokerAppUrl);
        }
    }, []);

    return (
        <footer className={classes.footer}>
            <Container maxWidth='lg'>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <nav>
                            <div className={classes.footerLinksContainer}>
                                <MuiLink
                                    id="privacyPolicy"
                                    underline="none"
                                    color="inherit"
                                    href={`${uinsureUrl}/customer-privacy-notice/`}
                                    target="_blank"
                                    className={classes.footerLink}
                                >
                                    Customer Privacy Notice
                                </MuiLink>
                                <MuiLink
                                    id="intermediaryPrivacyPolicy"
                                    underline="none"
                                    color="inherit"
                                    href={`${uinsureUrl}/intermediary-privacy-notice/`}
                                    target="_blank"
                                    className={classes.footerLink}
                                >
                                    Intermediary Privacy Notice
                                </MuiLink>
                                <MuiLink
                                    id="privacyPolicy"
                                    underline="none"
                                    color="inherit"
                                    href="https://www.uinsure.co.uk/media/22476/intermediary-terms-of-business-agreement.pdf"
                                    target="_blank"
                                    className={classes.footerLink}
                                >
                                    Intermediary TOBA
                                </MuiLink>
                            </div>
                        </nav>
                        <Copyright />
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
};

export default Footer;
