import { AppBar, makeStyles, Toolbar } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    link: {
        margin: theme.spacing(1, 2),
        fontSize: '0.8125rem',
        fontFamily: 'Spartan MB SemiBold',
    },
    toolbar: {
        minHeight: 104,
        margin: 'auto',
        maxWidth: '1060px',
        width: '100%',
        [theme.breakpoints.down(theme.breakpoints.width('md'))]: {
            minHeight: '88px',
        },
    },
    nav: {
        display: 'flex',
    },
    logo: {
        flexGrow: 1,
        '& img': {
            width: 130,
            height: 45,
            verticalAlign: 'middle',
        },
    },
    offset: {
        minHeight: 104,
        [theme.breakpoints.down(theme.breakpoints.width('md'))]: {
            minHeight: '88px',
        },
    },
}));

const Header = () => {
    const classes = useStyles();
    return (
        <>
            <AppBar position="fixed">
                <Toolbar variant="regular" className={classes.toolbar}>
                    <div className={classes.logo}>
                        <img
                            src="/img/uinsure-white-2021.svg"
                            alt="Uinsure"
                            data-testid="header-uinsure-logo"
                        />
                    </div>
                </Toolbar>
            </AppBar>
            <div className={classes.offset} />
        </>
    );
};

export default Header;
