import Typography from '@material-ui/core/Typography';
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        copyrightText: {
            fontFamily: 'Spartan MB',
            fontSize: '0.6875rem',
            textAlign: 'center',
            color: '#666666',
            lineHeight: '1.7',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'left',
            },
        },
    }),
);

const Copyright = () => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="body2" color="textSecondary" className={classes.copyrightText}>
                {'Copyright © '}
                {new Date().getFullYear()} Uinsure Ltd.
            </Typography>
            <Typography variant="body2" color="textSecondary" className={classes.copyrightText}>
                Registered in England and Wales No. 06046870
            </Typography>
            <Typography variant="body2" color="textSecondary" className={classes.copyrightText}>
                Uinsure Limited is Authorised and Regulated by the Financial Conduct Authority No.
                463689
            </Typography>
        </>
    );
};

export default Copyright;
