import axios from 'axios';
import { useEffect, useState } from 'react';
import {LoginModel} from "../components/forms/LoginModel";

const useCustomerPortal = (payload: string | null, loginModel: LoginModel) => {
  const [result, setResult] = useState({
    postcode: '',
    firstName: '',
  });
  const [error, setError] = useState(false);

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  useEffect(() => {
    async function fetchData(payload: string) {
      try {
        const url = 'api/customerportal/postcode';
        const body = JSON.stringify({
          payload: payload,
        });

        const response = await axios.post(url, body, {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          if (response.data === null) {
            setError(true);
          } else {
            setResult(response.data);
          }
        } else {
          setError(true);
        }
      } catch (error) {
        if (error.message !== 'Operation canceled by the user.') {
        }
        setError(true);
      }
    }

    if (payload !== '' && payload !== null) {
      fetchData(payload);
    }

    return function cleanup() {
      source.cancel('Operation canceled by the user.');
    };
  }, [payload, loginModel]);

  return { result, error };
};

export default useCustomerPortal;
