import { Box, Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
    SetPasswordErrorCode as ErrorCode,
    SetPasswordModel,
    SetPasswordModelInitial,
    NewPasswordModelSchema,
    ErrorResponse,
    TimeoutCheckResponse,
} from './forms/SetPasswordModels';
import SetPasswordForm from './forms/SetPasswordForm';
import Modal from './modal/Modal';

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(8),
    },
    header: {
        margin: 0,
        color: '#291B44',
    },
    error: {
        paddingBottom: theme.spacing(2),
    },
}));

function ShouldShowErrorToUser(error: ErrorCode) {
    return error === ErrorCode.AlreadyUsed || error === ErrorCode.TimedOut;
}

const SetPassword = (props: any) => {
    const classes = useStyles();
    const [isResetting] = useState(props.resetting);
    const [loading, setLoading] = useState(false);
    const [errorCode, setErrorCode] = useState(ErrorCode.None);
    const [timeoutError, setTimeoutError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [success, setSuccess] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const code = new URLSearchParams(window.location.search).get('code');

    useEffect(() => {
        (async function checkTimeout() {
            try {
                const url = 'api/SetPassword/timeoutCheck';
                const body = JSON.stringify({ code: code });
                const response = await axios.post<ErrorResponse | TimeoutCheckResponse>(url, body, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    validateStatus: () => true,
                });
                if (response.status === 200) {
                    const timeoutCheckResponse = response.data as TimeoutCheckResponse;
                    setTimeoutError(timeoutCheckResponse.hasTimedOut);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [code]);

    if (redirect) {
        return <Redirect to='/login' />;
    }

    const setPassword = async (values: SetPasswordModel) => {
        values.Code = code!;
        try {
            setLoading(true);
            const url = 'api/SetPassword';
            const body = JSON.stringify(values);
            const response = await axios.post<ErrorResponse | boolean>(url, body, {
                headers: {
                    'Content-Type': 'application/json',
                },
                validateStatus: () => true,
            });

            if (response.status >= 200 || response.status < 300) {
                setSuccess(true);
                setLoading(false);
                setErrorCode(ErrorCode.None);
                setErrorText('');
            } else {
                const error = response.data as ErrorResponse;
                setSuccess(false);
                setLoading(false);
                setErrorCode(error.errorCode);
                setErrorText(error.message);
                if (error.errorCode === ErrorCode.TimedOut) {
                    setTimeoutError(true);
                }
            }
        } catch (error) {
            console.log(error);
            setSuccess(false);
            setLoading(false);
            setErrorCode(ErrorCode.Exception);
            setErrorText(error);
        }
    };

    const headerCopy = isResetting ? 'Reset Password' : 'Activate your account';
    const errorCopy = isResetting ? 'Password is not reset' : 'Account is not activated';
    const successCopy = isResetting
        ? 'Your password has been successfully reset.'
        : 'Your account has been successfully activated.';

    const error = !code || timeoutError;

    return (
        <Container>
            <Box className={classes.paper}>
                <img src='/img/home-logo.png' alt='Uinsure' data-testid='header-changed-password-logo' />
                <Box mt={2} mb={3}>
                    <Typography variant='h1' className={classes.header}>
                        {headerCopy}
                    </Typography>
                </Box>
                {error ? (
                    <Box mt={5} data-testid='error'>
                        <Typography variant='body1' className={classes.error}>
                            There was an error during the attempt to {headerCopy.toLocaleLowerCase()}.
                        </Typography>
                        {timeoutError && (
                            <Typography variant='body1' className={classes.error}>
                                One time link has expired.
                            </Typography>
                        )}
                        <Typography variant='body1' className={classes.error}>
                            Call us on 0344 844 3844 if you require further assistance.
                        </Typography>
                    </Box>
                ) : (
                    <Formik
                        initialValues={SetPasswordModelInitial}
                        validationSchema={NewPasswordModelSchema}
                        onSubmit={(values) => {
                            setPassword(values);
                        }}
                    >
                        <SetPasswordForm isLoading={loading} isResetting={isResetting} />
                    </Formik>
                )}

                <Modal
                    data-testid='error-modal'
                    onClose={() => setErrorCode(ErrorCode.None)}
                    open={errorCode !== ErrorCode.None}
                >
                    <Grid container direction='column' justify='center' alignItems='center'>
                        <Grid item>
                            <Box mt={2} mb={4}>
                                <Typography variant='h1' className={classes.header}>
                                    Error
                                </Typography>
                            </Box>
                            <Box mb={3}>
                                <Typography variant='body1'>{errorCopy}</Typography>
                            </Box>
                            {ShouldShowErrorToUser(errorCode) && (
                                <Box mb={3}>
                                    <Typography variant='body1'>{errorText}</Typography>
                                </Box>
                            )}
                            <Box mb={3}>
                                <Typography variant='body1'>
                                    Call us on 0344 844 3844 if you require further assistance
                                </Typography>
                            </Box>
                            <Box mt={6}>
                                <Button
                                    data-testid='set-password-error-close'
                                    type='button'
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setErrorCode(ErrorCode.None)}
                                    fullWidth
                                >
                                    Okay
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Modal>

                <Modal data-testid='success-modal' onClose={() => setRedirect(true)} open={success}>
                    <Grid container direction='column' justify='center' alignItems='center'>
                        <Grid item>
                            <Box mt={2} mb={4}>
                                <Typography variant='h1' className={classes.header}>
                                    Success
                                </Typography>
                            </Box>
                            <Box mb={3}>
                                <Typography>{successCopy}</Typography>
                            </Box>
                            <Box mb={3}>
                                <Typography variant='body1'>
                                    To log in and access the Uinsure platform, please click below
                                </Typography>
                            </Box>
                            <Box mt={6}>
                                <Button
                                    data-testid='set-password-success-close'
                                    type='button'
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setRedirect(true)}
                                    fullWidth
                                >
                                    Continue
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Modal>
            </Box>
        </Container>
    );
};

export default SetPassword;
