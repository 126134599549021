import { Typography } from '@material-ui/core';
import React from 'react';
import MaterialModal from './MaterialModal';
import ModalContent from './ModalContent';
import ModalHeader from './ModalHeader';

interface ModalProps {
    open: boolean;
    showClose?: boolean;
    onClose: () => void;
    title?: string;
    testId?: string;
    children: JSX.Element | string;
    centerContent?: boolean;
    width?: ModalWidth;
    centerTitle?: boolean;
    disableBackdropClick?: boolean;
    disableEscapeKeyDown?: boolean;
}

export enum ModalWidth {
    _460 = '460px',
    _520 = '520px',
    _560 = '560px',
    _800 = '800px',
    _1040 = '1040px',
    _1260 = '1260px',
}

const Modal = ({
    open,
    showClose = true,
    onClose,
    title,
    children,
    centerContent = false,
    width = ModalWidth._520,
    centerTitle = false,
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
}: ModalProps) => {
    return (
        <MaterialModal
            open={open}
            onClose={onClose}
            width={width}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
        >
            <>
                {title && (
                    <ModalHeader showClose={showClose} onClick={onClose} centerTitle={centerTitle}>
                        <Typography data-testid='modal-header' variant='h3' style={{ margin: 0 }}>
                            {title}
                        </Typography>
                    </ModalHeader>
                )}
                <ModalContent centerContent={centerContent}>{children}</ModalContent>
            </>
        </MaterialModal>
    );
};

export default Modal;
