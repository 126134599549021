import axios from 'axios';
import { useEffect, useState } from 'react';

interface ForgotPasswordResponse {
    Success: boolean;
}

const baseFunctionsUrl = process.env.REACT_APP_FUNCTION_BASE_URL || 'http://localhost:7071';
const baseFunctionsStagingUrl =
    process.env.REACT_APP_FUNCTION_BASE_STAGING_URL ||
    'https://uinsureapifunctionsqa.azurewebsites.net';

const useForgotPassword = (
    emailAddress: string,
    isForgotPasswordRequest: boolean,
    onRequestFinished: Function,
) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [result, setResult] = useState<ForgotPasswordResponse>();
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        async function postPasswordReset(emailAddress: string) {
            try {
                setResult(undefined);
                setError(false);
                setLoading(true);

                const isStaging = window.location.href.indexOf('idp.test') > 0;
                const url = `${
                    isStaging ? baseFunctionsStagingUrl : baseFunctionsUrl
                }/api/forgotpassword`;

                const body = JSON.stringify({
                    EmailAddress: emailAddress,
                });

                const response = await axios.post(url, body, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    cancelToken: source.token,
                });

                if (response.status === 200) {
                    setResult(response.data);
                    setLoading(false);
                }

                return response;
            } catch (error) {
                if (error.message !== 'Operation canceled by the user.') {
                    setLoading(false);
                }
                setError(true);
                console.log(error);
            } finally {
                onRequestFinished();
            }
        }

        if (emailAddress && isForgotPasswordRequest) {
            postPasswordReset(emailAddress);
        }

        return function cleanup() {
            source.cancel('Operation canceled by the user.');
            setLoading(false);
        };
    }, [isForgotPasswordRequest, emailAddress]);

    return { result, isLoading, error };
};

export default useForgotPassword;
