import { Backdrop, createStyles, Fade, makeStyles, Modal, Paper, Theme } from '@material-ui/core';
import React from 'react';
import { ModalWidth } from './Modal';

interface Props {
    children: JSX.Element | JSX.Element[];
    open: boolean;
    onClose?: (_: React.MouseEvent<HTMLElement>) => void;
    width: ModalWidth;
    disableBackdropClick?: boolean;
    disableEscapeKeyDown?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: (styleProps: any) => ({
            position: 'absolute',
            maxWidth: styleProps.width,
            width: '95%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '&:focus': {
                outline: 'none',
            },
        }),
        paper: {
            margin: theme.spacing(2),
        },
    })
);

const MaterialModal = ({
    children,
    open,
    width,
    onClose,
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
}: Props) => {
    const classes = useStyles({ width });
    return (
        <Modal
            data-testid='modal'
            aria-labelledby='simple-modal-title'
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 100,
            }}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
        >
            <Fade in={open}>
                <div className={classes.modal}>
                    <Paper variant='outlined'>{children}</Paper>
                </div>
            </Fade>
        </Modal>
    );
};

export default MaterialModal;
