import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { Field, Form } from 'formik';
import React from 'react';
import MaterialTextField from '../MaterialTextField';
import useDisableContinue from '../../hooks/useDisableContinue';

interface Props {
    isLoading: boolean;
    isResetting: boolean;
}

const SetPasswordForm = ({ isLoading, isResetting }: Props) => {
    const { isDisabled } = useDisableContinue();

    const buttonText = isResetting ? 'Reset password' : 'Activate account';

    return (
        <Form noValidate>
            <Box mb={2}>
                <Field
                    name='Password'
                    id='password-password'
                    data-testid='password-password'
                    label='New Password'
                    component={MaterialTextField}
                    placeholder=''
                    type='password'
                />
            </Box>
            <Grid container>
                <Grid item xs={12}>
                    <ul>
                        <li>Must contain at least 1 capital</li>
                        <li>Must have a minimum of 8 characters</li>
                        <li>Must have a mixture of letters and numbers</li>
                    </ul>
                </Grid>
            </Grid>
            <Box mb={3}>
                <Field
                    name='RetypedPassword'
                    id='password-retyped-password'
                    data-testid='password-retyped-password'
                    label='Confirm new password'
                    component={MaterialTextField}
                    placeholder=''
                    type='password'
                />
            </Box>
            <Box mb={2}>
                <Grid container direction='row' justify='center' alignItems='center'>
                    <Grid item xs={12}>
                        <Button
                            data-testid='submit-set-password'
                            disabled={isDisabled || isLoading}
                            type='submit'
                            variant='contained'
                            color='primary'
                            fullWidth
                        >
                            <>
                                {buttonText}&nbsp;&nbsp;
                                {isLoading && <CircularProgress size={24} />}
                            </>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Form>
    );
};

export default SetPasswordForm;
