import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { Field, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import useDisableContinue from '../../hooks/useDisableContinue';
import MaterialDateInput from '../MaterialDatePicker';

interface Props {
  isLoading: boolean;
}

const CustomerPortalLoginForm = ({ isLoading }: Props) => {
  const { isDisabled } = useDisableContinue();

  return (
    <Form noValidate>
      <Field name='DateOfBirth' id='verify-date-of-birth' label='Date of Birth' component={MaterialDateInput} />
      <Grid container direction='row' justify='center' alignItems='center'>
        <Grid item xs={12}>
          <Button
            data-testid='login-sign-in'
            disabled={isDisabled || isLoading}
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
          >
            <>Sign in&nbsp;&nbsp;{isLoading && <CircularProgress size={24} />}</>
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default CustomerPortalLoginForm;
