import { Button, CircularProgress, Grid } from '@material-ui/core';
import { Field, Form } from 'formik';
import React from 'react';
import MaterialTextField from '../MaterialTextField';
import useDisableContinue from '../../hooks/useDisableContinue';

interface Props {
    isLoading: boolean;
}

const LoginForm = ({ isLoading }: Props) => {
    const { isDisabled } = useDisableContinue();

    return (
        <Form noValidate>
            <Field
                name="EmailAddress"
                id="login-email-address"
                label="Email address"
                component={MaterialTextField}
                placeholder="Email address"
            />
            <Field
                name="Password"
                id="login-password"
                label="Password"
                component={MaterialTextField}
                placeholder="Password"
                type="password"
            />
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12}>
                    <Button
                        data-testid="login-sign-in"
                        disabled={isDisabled || isLoading}
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        <>Sign in&nbsp;&nbsp;{isLoading && <CircularProgress size={24} />}</>
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
};

export default LoginForm;
