import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface Props {
    centerContent?: boolean;
    testId?: string;
    children: JSX.Element | JSX.Element[] | string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalContent: (styleProps: any) => ({
            padding: theme.spacing(0, 2, 4, 2),
            textAlign: styleProps.centerContent ? 'center' : 'left',
            maxHeight: '70vh',
            overflowY: 'auto',
            overflowX: 'hidden',
        }),
    }),
);

const ModalContent = ({ children, testId, centerContent }: Props) => {
    const classes = useStyles({ centerContent });
    return (
        <div data-testid={testId || 'modal-content'} className={classes.modalContent}>
            {children}
        </div>
    );
};

export default ModalContent;
