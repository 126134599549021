import React from 'react';
import { Route } from 'react-router-dom';
import './App.css';
import { Layout } from './components/Layout';
import Login from './components/Login';
import Error from './components/Error';
import Logout from './components/Logout';
import SetPassword from './components/SetPassword';

function App() {
    return (
        <Layout>
            <Route exact path='/' component={Login} />
            <Route path='/login' component={Login} />
            <Route path='/error' component={Error} />
            <Route path='/logout' component={Logout} />
            <Route path='/activate' component={SetPassword} />
            <Route path='/reset' render={() => <SetPassword resetting />} />
        </Layout>
    );
}

export default App;
