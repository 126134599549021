import { Avatar, Box, Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React from 'react';

const brokerAppUrl = process.env.REACT_APP_BROKER_APP_URL || 'http://localhost:8000';
const brokerAppStagingUrl =
    process.env.REACT_APP_BROKER_APP_STAGING_URL || 'https://quotes.test.uinsure.co.uk';

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(8),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.light,
    },
    header: {
        margin: 0,
    },
}));

const Error = () => {
    const classes = useStyles();

    const handleLogin = () => {
        if (window.location.href.indexOf('idp.test') > 0) {
            window.location.href = brokerAppStagingUrl;
        } else {
            window.location.href = brokerAppUrl;
        }
    };

    return (
        <Container maxWidth={'xs'}>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <ErrorOutlineIcon />
                </Avatar>
                <Typography variant="h1" className={classes.header}>
                    There has been a error.
                </Typography>
                <Box mt={4} mb={4}>
                    <Typography variant="body2">
                        Please try to login again by clicking below.
                    </Typography>
                </Box>
                <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
                    Login
                </Button>
            </div>
        </Container>
    );
};

export default Error;
