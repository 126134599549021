import { Avatar, Box, Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React, { useEffect } from 'react';
import useAxios from '../hooks/useAxios';

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(8),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.light,
    },
    header: {
        margin: 0,
    },
    iframe: {
        display: 'none',
    },
}));

const Logout = () => {
    const classes = useStyles();
    const query = window.location.search;
    const logoutIdQuery = query && query.toLowerCase().indexOf('?logoutid=') == 0 && query;
    const url = `/api/authenticate/logout${logoutIdQuery}`;

    const data = useAxios(url, { withCredentials: true }) as any;

    const handleLogin = () => {
        if (data && data.postLogoutRedirectUri) window.location.href = data.postLogoutRedirectUri;
    };

    return (
        <Container maxWidth={'xs'}>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <ExitToAppIcon />
                </Avatar>
                <Typography variant="h1" className={classes.header}>
                    You have been logged out successfully.
                </Typography>
                <Box mt={4} mb={4}>
                    <Typography variant="body2">Click below to login.</Typography>
                </Box>
                <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
                    Login
                </Button>
            </div>
            {data && data.signOutIFrameUrl && (
                <iframe
                    className={classes.iframe}
                    src={data.signOutIFrameUrl}
                    height="0"
                    width="0"
                ></iframe>
            )}
        </Container>
    );
};

export default Logout;
